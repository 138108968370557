<template>
  <div>
    <l-search>
      <l-form layout="inline" laba-width="70px">
        <l-form-item label="系统">
          <l-select
            v-model="appId"
            placeholder="请选择系统"
          >
            <l-select-option
              v-for="item in authAppList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.cnName }}
            </l-select-option>
          </l-select>
        </l-form-item>
      </l-form>
      <template slot="right">
        <l-button class="l-float-right" @click="resetForm">
          重置
        </l-button>
      </template>
    </l-search>

    <div>
      <div v-if="appId" style="text-align:right;">
        <l-button
          v-auth="'AUTH_MENU-SAVE'"
          type="link"
          class="l-mr-16"
          @click="syncMenuResource"
        >
          同步所有菜单资源
        </l-button>
        <l-button
          v-auth="'AUTH_MENU-SAVE'"
          text="重置中..."
          class="l-mr-16"
          @click="resetMenu"
        >
          重置菜单
        </l-button>
        <l-button
          v-auth="'AUTH_MENU-SAVE'"
          type="primary"
          @click="save"
        >
          保存
        </l-button>
      </div>
      <div class="c-menu-tree">
        <l-tree
          :tree-data="treeData"
          :replace-fields="{children:'children', title:'name', key:'id'}"
          :default-expand-all="true"
          :block-node="true"
          :draggable="true"
          @drop="onDrop"
          @dragenter="handleDragEnd"
          @select="nodeClick"
        >
          <!--
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          :expand-on-click-node="false"-->
          <span
            slot="custom"
            slot-scope="item"
            class="custom-tree-node"
          >
            <span :class="{lineThrough: item.isActive === 0}">
              {{ item.name }}
              <span v-if="item.level === 2 || item.level === 3">({{ item.resources.length }})</span>
            </span>
            <span>
              <l-button
                v-if="item.level < 3"
                v-auth="'AUTH_MENU-SAVE'"
                type="link"
                @click="appendSub(item)"
              >子菜单</l-button>
              <l-button
                v-if="item.id !== -1"
                v-auth="'AUTH_MENU-SAVE'"
                type="link"
                @click="append(item)"
              >同级菜单</l-button>
              <l-button
                v-if="item.id !== -1"
                v-auth="'AUTH_MENU-SAVE'"
                type="link"
                @click="remove(item)"
              >删除</l-button>
            </span>
          </span>
        </l-tree>
      </div>

      <!-- 右边菜单编辑 -->
      <div v-if="(typeof menu.id) !== 'undefined'" class="c-menu-tree_setting">
        <div class="form-row form-group">
          <label class="text-right">
            <span class="require"></span>菜单id:
          </label>
          <l-input
            :value="menu.id+''"
            type="text"
            :disabled="true"
            placeholder="菜单id"
          />
        </div>
        <div class="form-row form-group">
          <label class="text-right">
            <span class="require"></span>排序:
          </label>
          <l-input
            :value="menu.sort+''"
            type="text"
            :disabled="true"
            placeholder="排序"
          />
        </div>
        <div class="form-row form-group">
          <label class="text-right">
            <span class="require">*</span>图标:
          </label>
          <l-input
            v-model="menu.icon"
            type="text"
            placeholder="请输入图标"
          />
        </div>
        <div class="form-row form-group">
          <label class="text-right">
            <span class="require">*</span>菜单名字:
          </label>
          <l-input
            v-model="menu.name"
            type="text"
            placeholder="请输入菜单名字"
          />
        </div>
        <div class="form-row form-group">
          <label class="text-right">
            <span class="require"></span>扩展字段:
          </label>
          <l-input
            v-model="menu.ext"
            type="text"
            placeholder="扩展字段"
          />
        </div>
        <div
          v-show="menu.level === 1"
          class="form-row form-group"
        >
          <label class="text-right">
            <span class="require"></span>菜单显示表达式:
          </label>
          <l-input
            v-model="menu.showReg"
            type="text"
            placeholder="菜单显示表达式"
          />
        </div>
        <div class="form-row form-group">
          <label class="text-right">
            <span class="require">*</span>菜单地址:
          </label>
          <l-input
            v-model="menu.url"
            type="text"
            placeholder="请输入菜单地址"
          />
        </div>
        <div class="form-row form-group">
          <label class="text-right">
            <span class="require">*</span>是否显示:
          </label>
          <l-radio-group v-model="menu.isActive">
            <l-radio :value="1">显示</l-radio>
            <l-radio :value="0">隐藏</l-radio>
          </l-radio-group>
        </div>
        <div v-if="menu.level === 2 || menu.level === 3" class="c-menu-transfer">
          <div class="form-row form-group">
            <div class="form-line"></div>
          </div>
          <div class="form-row form-group l-mb-10">
            <l-button type="primary" @click="qucikSelect()">同名资源</l-button>
          </div>
          <a-transfer
            filterable
            :filter-option="filterResourceMethod"
            :target-keys="menu.resources"
            show-search
            filter-placeholder="请输入资源名称"
            :data-source="menuResources"
            :render="item => item.name"
            :titles="['全部资源', '关联资源']"
            @change="handleResourcesChange(menu, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthMenuList',
  data() {
    return {
      appId: '',
      menuResources: [],
      treeData: [],
      menu: {}
    }
  },
  computed: {
    ...mapState({
      authAppList: state => state.auth.authAppList
    })
  },
  watch: {
    appId() {
      if (!this.appId) {
        return
      }
      this.resetData()
      this.getMenuResources()
    }
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    // 资源改变
    handleResourcesChange(menu, targetKeys) {
      menu.resources = targetKeys
    },
    // 添加子菜单
    appendSub(data) {
      const menu = {
        id: 0,
        isActive: 1,
        name: '新建菜单',
        url: '',
        icon: 'unordered-list',
        resources: [],
        children: []
      }
      data.children.push(menu)
      this.setLevel(this.treeData)
      this.menu = menu
      const tmp = this.treeData
      this.treeData = []
      this.$nextTick(() => {
        this.treeData = tmp
      })
    },
    // 删除菜单
    remove(data) {
      this.$confirm({
        title: '确认是否删除，删除后数据将无法恢复？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this._remove(data.id, this.treeData)
          this.menu = {}
        }
      })
    },
    _remove(id, arr) {
      arr.some((item, index) => {
        if (item.id === id) {
          arr.splice(index, 1)
          return true
        } else if (item.children && item.children.length > 0) {
          this._remove(id, item.children)
        }
      })
    },
    // 添加同级菜单
    append(data) {
      const menu = {
        id: 0,
        isActive: 1,
        name: '新建菜单',
        url: '',
        icon: 'unordered-list',
        resources: [],
        children: []
      }
      if (data.parentId) {
        this._appendChild(data.parentId, this.treeData[0].children, menu)
      } else {
        this.treeData[0].children.push(menu)
      }
      this.setLevel(this.treeData)
      this.menu = menu
    },
    _appendChild(id, arr, menu) {
      arr.some(item => {
        if (item.id === id) {
          item.children.push(menu)
          return true
        } else if (item.children && item.children.length > 0) {
          this._appendChild(id, item.children, menu)
        }
      })
    },
    handleDragEnd() {
      this.setLevel(this.treeData)
    },
    /* eslint-disable */
    // 拖拽之后不会更新节点,需要手动更新
    // 参考: https://blog.csdn.net/qq_27331631/article/details/109616247
    onDrop(info) {
      // 目标节点
      const node = info.node
      // 拖拽节点
      const dragNode = info.dragNode
      const dragNodeData = dragNode.dataRef
      const nodeData = node.dataRef
      const dropPos = info.node.pos.split('-')
      var paramData = []
      // 当info.dropToGap为true,说明只是同级或者跨级排序，只需要寻找目标节点的父ID，获取其对象以及所有的子节点，并为子节点设置当前对象的ID为父ID即可
      // 当info.dropToGap为false,说明拖拽节点成为了目标节点的子节点，只需要获取目标节点对象即可
      // data为目标节点
      var data = info.dropToGap
        ? node.$parent.dataRef === undefined
          ? node.$parent.treeData
          : node.$parent.dataRef
        : node.dataRef

      // 说明拖拽节点拖拽到了跟节点层级(目标节点层级的所有节点)
      var nodeArray =
        dropPos.length === 2 && info.dropToGap
          ? data
          : data.children === undefined
          ? []
          : data.children
      // 判断是否包含当前拖拽节点，包含剔除，然后在目标节点指定位置添加拖拽节点数据
      nodeArray = nodeArray.filter((item) => {
        if (item.id !== dragNodeData.id) {
          return true
        }
      })
      // 获取插入的下标
      var index;
      // 当拖拽节点和目标节点处于同级别时，等于info.dropPosition
      if (nodeData.pid === dragNodeData.pid) {
        index = info.dropPosition
      } else {
        index =
          dropPos[dropPos.length - 1] < info.dropPosition
            ? info.dropPosition
            : dropPos[dropPos.length - 1]
      }
      // 如果是拖入节点内，那么直接push到最后，否则插入指定位置
      if (!info.dropToGap) {
        nodeArray.push(dragNodeData)
      } else {
        nodeArray.splice(index === -1 ? 0 : index, 0, dragNodeData)
      }
      // 设置父ID,当dropPos.length为2说明在第一级，pid为空
      nodeArray.forEach((element) => {
        element.pid = dropPos.length === 2 && info.dropToGap ? '' : data.id
      })
	    // 拼装参数
      nodeArray.forEach((element, i) => {
        var dept = {
          id: element.id,
          parentId: element.pid,
          sort: i,
        }
        paramData.push(dept)
      })
      
      // 判断是否移动到最高层级, 移动到最高层级则不改变树
      let flag = true
      nodeArray.some(item => {
        if (item.id === -1) {
          flag = false
          return true
        }
      })
      if (!flag) {
        return
      }
      let treeData = JSON.parse(JSON.stringify(this.treeData))
      this._remove(info.dragNodesKeys[info.dragNodesKeys.length - 1], treeData)
      this._dropAppendChild(data.id, treeData, nodeArray)
      this.treeData = treeData
    },
    _dropAppendChild(id, arr, children) {
      arr.some(item => {
        if (item.id === id) {
          item.children = children
          return true
        } else if (item.children && item.children.length > 0) {
          this._dropAppendChild(id, item.children, children)
        }
      })
    },
    /* eslint-enable */
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.id === -1) {
        return false
      }
      if (dropNode.data.level === 3) {
        return type !== 'inner'
      }
      return true
    },
    allowDrag(draggingNode) {
      if (draggingNode.data.id === -1) {
        return false
      }
      return true
    },
    nodeClick(id) {
      let menu
      id = id[0]
      if (id === -1) {
        menu = {}
      } else {
        const menuList = this.$utils.flatDeep(this.treeData, 'children')
        menu = menuList.find(item => item.id === id)
        menu.isActive = menu.isActive || 1
      }
      this.menu = menu
    },
    setLevel(data, level, sort) {
      level = level || 0
      sort = sort || 0
      if (!data || data.length === 0) {
        return sort
      }
      data.forEach(item => {
        item.level = level
        item.appId = this.appId
        item.sort = sort++
        item.children = item.children || []
        sort = this.setLevel(item.children, level + 1, sort)
      })
      return sort
    },
    resetMenu() {
      this.$confirm({
        title: '确认是否重置菜单，重置后修改的数据将无法恢复？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.resetData()
        }
      })
    },
    async resetData() {
      this.menu = {}
      this.treeData = []
      const data = await this.$store.dispatch('auth/authMenuList', {
        appId: this.appId
      })
      this.treeData = data || []
      this.treeData = this._setCustomSlot(this.treeData)
      this.treeData = [
        {
          id: -1,
          name: '全部菜单',
          slots: { title: 'custom' },
          scopedSlots: { title: 'custom' },
          children: this.treeData
        }
      ]
      this.initMenuResource(this.treeData)
      this.setLevel(this.treeData)
    },
    _setCustomSlot(treeData) {
      treeData.forEach(item => {
        item.scopedSlots = { title: 'custom' }
        if (item.children && item.children.length > 0) {
          item.children = this._setCustomSlot(item.children)
        }
      })
      return treeData
    },

    initMenuResource(menus) {
      if (!menus || menus.length === 0) {
        return
      }
      menus.forEach(menu => {
        menu.resources = menu.resources || ''
        menu.resources = menu.resources.replace(/^,/, '').replace(/,$/, '')
        if (menu.resources) {
          menu.resources = menu.resources.split(',')
          for (let i = 0; i < menu.resources.length; i++) {
            menu.resources[i] = Number(menu.resources[i])
          }
        } else {
          menu.resources = []
        }
        this.initMenuResource(menu.children)
      })
    },

    setResource(menus) {
      if (!menus || menus.length === 0) {
        return
      }
      menus.forEach(menu => {
        menu.resources = menu.resources || []
        menu.resources = menu.resources.join(',')
        if (menu.resources) {
          menu.resources = ',' + menu.resources + ','
        }
        this.setResource(menu.children)
      })
    },
    async save() {
      this.setLevel(this.treeData)
      const data = JSON.parse(JSON.stringify(this.treeData[0]))
      this.setResource([data])
      data.appId = this.appId
      await this.$store.dispatch('auth/authMenuSave', data)
      this.resetData()
    },
    async resetForm() {
      await this.$store.dispatch('auth/authAppList', {
        size: 1000
      })
      this.appId = ''
      this.treeData = []
    },
    filterResourceMethod(query, item) {
      return item.name.indexOf(query) > -1
    },
    async getMenuResources() {
      const data = await this.$store.dispatch('auth/authResourcesListForApp', {
        appId: this.appId
      })
      data.forEach(item => {
        item.key = item.id
      })
      this.menuResources = data
    },
    syncMenuResource() {
      const tmpTreeData = this.treeData[0].children
      tmpTreeData.forEach(item1 => {
        item1.children.forEach(item2 => {
          item2.children.forEach(item3 => {
            this.qucikSelect(item3)
          })
        })
      })
    },
    qucikSelect(menu) {
      menu = menu || this.menu

      if (!menu || !menu.name) return

      const resources = []
      this.menuResources.forEach(resource => {
        if (resource.name.indexOf(menu.name + '-') !== -1) {
          resources.push(resource.id)
        }
      })
      menu.resources = menu.resources || []
      resources.forEach(id => {
        if (menu.resources.indexOf(id) === -1) {
          menu.resources.push(id)
        }
      })
      const map = {}
      menu.resources.forEach(id => {
        map[id] = id
      })
      menu.resources = []
      for (const key in map) {
        menu.resources.push(Number(key))
      }
    }
  }
}
</script>

<style lang="scss">
.lineThrough {
  text-decoration: line-through;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 10px;
  height: 20px;
  line-height: 20px;
  .ant-btn-link {
    line-height: 20px;
    height: 20px;
    padding: 0 8px;
  }
}

.form-line {
  height: 1px;
  background-color: #dcdfe6;
  margin: 10px 0;
}

.c-menu-tree {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.c-menu-tree_setting {
  display: inline-block;
  width: 50%;
  vertical-align:top;
  box-sizing: border-box;
  padding-left: 20px;
}
</style>
